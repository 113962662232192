import {
  IoAlertOutline,
  IoCalendarNumberOutline,
  IoCalendarOutline,
  IoCallOutline,
  IoCashOutline,
  IoCheckmark,
  IoCheckmarkCircleOutline,
  IoCheckmarkDoneOutline,
  IoCloseCircleOutline,
  IoFlagOutline,
  IoHeartOutline,
  IoHeart,
  IoHelpCircleOutline,
  IoHourglassOutline,
  IoLogoUsd,
  IoMedalOutline,
  IoNewspaperOutline,
  IoPersonOutline,
  IoReceiptOutline,
  IoShieldCheckmarkOutline,
  IoThumbsDownOutline,
  IoTimeOutline,
  IoWalletOutline,
  IoCheckmarkCircleSharp,
  IoChevronDownOutline,
  IoEyeOutline,
  IoClose,
  IoStar,
  IoStarHalf,
  IoStarOutline,
  IoCaretDown,
  IoSearchCircle,
  IoCaretDownOutline,
  IoSearch,
  IoCalendar,
  IoCloudUploadOutline,
  IoRemoveCircle,
  IoTrendingUp,
  IoTrendingDown,
  IoChevronDown,
  IoLockClosed,
  IoAdd,
  IoRemove,
  IoStatsChart,
  IoStatsChartOutline,
  IoCalendarNumber,
  IoTime,
  IoConstruct,
  IoPeopleOutline,
  IoSettingsOutline,
  IoWallet,
  IoArrowUp,
  IoArrowDown,
  IoPerson,
  IoCubeOutline,
  IoPieChartOutline,
  IoArrowDownCircleOutline,
  IoArrowUpCircleOutline,
  IoAddCircleOutline,
  IoSettings,
  IoArrowForward,
  IoHome,
  IoLink,
  IoPersonAdd,
  IoPeople,
  IoBagAdd,
  IoChevronForward,
  IoCopyOutline,
  IoSwapHorizontal,
  IoLogoFacebook,
  IoLogoYoutube,
  IoLogoTiktok,
  IoColorWandOutline,
  IoBagOutline,
  IoCopy,
  IoHomeOutline,
  IoCash,
  IoReader,
  IoRefresh,
  IoChatboxEllipses,
  IoWarning,
  IoAlertCircle,
  IoAlertCircleOutline,
  IoLocation,
  IoShareOutline,
  IoRestaurant,
} from "react-icons/io5";
import { FaRegFrown, FaRegMeh, FaRegSmile, FaDog } from "react-icons/fa";
import { MdDoubleArrow, MdChair } from "react-icons/md";
import { TbAirConditioning,TbIroningFilled  } from "react-icons/tb";
import { PiCookingPotFill } from "react-icons/pi";
import { FaToolbox } from "react-icons/fa6";
import { GiVacuumCleaner } from "react-icons/gi";

const icons = {
  IoAlertOutline,
  IoCalendarNumberOutline,
  IoCalendarNumber,
  IoCalendarOutline,
  IoCallOutline,
  IoCashOutline,
  IoCheckmark,
  IoCheckmarkCircleOutline,
  IoCheckmarkDoneOutline,
  IoCloseCircleOutline,
  IoFlagOutline,
  IoHeartOutline,
  IoHelpCircleOutline,
  IoHourglassOutline,
  IoLogoUsd,
  IoMedalOutline,
  IoNewspaperOutline,
  IoPersonOutline,
  IoReceiptOutline,
  IoShieldCheckmarkOutline,
  IoThumbsDownOutline,
  IoTimeOutline,
  IoWalletOutline,
  IoCheckmarkCircleSharp,
  IoChevronDownOutline,
  IoEyeOutline,
  IoClose,
  IoStar,
  IoStarHalf,
  IoStarOutline,
  IoCaretDown,
  IoSearchCircle,
  IoCaretDownOutline,
  IoSearch,
  IoCalendar,
  IoCloudUploadOutline,
  IoRemoveCircle,
  IoTrendingUp,
  IoTrendingDown,
  IoChevronDown,
  IoLockClosed,
  IoAdd,
  IoRemove,
  IoStatsChart,
  IoTime,
  IoConstruct,
  IoPeopleOutline,
  IoSettingsOutline,
  IoStatsChartOutline,
  IoWallet,
  IoArrowUp,
  IoArrowDown,
  IoPerson,
  IoCubeOutline,
  IoPieChartOutline,
  IoArrowDownCircleOutline,
  IoArrowUpCircleOutline,
  IoAddCircleOutline,
  IoSettings,
  IoArrowForward,
  IoHome,
  IoLink,
  IoPersonAdd,
  IoPeople,
  IoBagAdd,
  IoChevronForward,
  IoCopyOutline,
  IoSwapHorizontal,
  IoLogoFacebook,
  IoLogoYoutube,
  IoLogoTiktok,
  IoColorWandOutline,
  IoBagOutline,
  IoCopy,
  IoHomeOutline,
  IoCash,
  IoReader,
  IoRefresh,
  IoChatboxEllipses,
  IoWarning,
  IoAlertCircle,
  IoAlertCircleOutline,
  IoLocation,
  IoShareOutline,
  IoHeart,
  IoRestaurant,
  // Font Awesome 5
  FaRegFrown,
  FaRegMeh,
  FaRegSmile,
  FaDog,
  // Material Design icons
  MdDoubleArrow,
  MdChair,
  // Tabler Icons
  TbAirConditioning,
  TbIroningFilled,
  // Phosphor Icons
  PiCookingPotFill,
  // Fa Icons
  FaToolbox,
  // Gi Icons
  GiVacuumCleaner,
};

export default icons;
